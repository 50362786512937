import {
  Account,
  Connection,
  PublicKey,
  Keypair,
  LAMPORTS_PER_SOL,
  SystemProgram,
  TransactionInstruction,
  Transaction,
  sendAndConfirmTransaction,
} from '@solana/web3.js';

import BN from "bn.js";

import * as anchor from '@project-serum/anchor';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner } from "@/libs/solanaConnection";
import { anchor_init, get_pda, prepare_ata } from "@/libs/solanaProgram";
import { signAndSendTransaction, signAndSendMultipleTransactions } from "@/libs/wallet";
const mpl_token_metadata = require('@metaplex-foundation/mpl-token-metadata');

import { TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID, Token, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createTransferCheckedInstruction, MintLayout  } from "@solana/spl-token";

import _idl from "@/IDL_artrade.json";
const idl = _idl;

// Address of the deployed program.
// const program_id = new PublicKey('7DujNcbL1Q6tU4dvVjoc7sbYxF7CxC2mRwm1NWDQ94vo');
// const program_id = new PublicKey('MqSCtyPo4u5zJ2iDipg1SiqwdzCrUavkJao1hUUvw3c'); // artrade devnet
const program_id = new PublicKey('7MWnh5hHuX13iaHZmJuRG2u526MvBNbNNJ2cEgZYyk2i'); // artrade mainnet fred

var connection = null;


import axios from 'axios';
import bs58 from "bs58";

const VAULT_ACCOUNT_SEED = 'collection owner';

const VAULT_ACCOUNT_PREFIX = 'artradevault_221026';
const VAULT_AUTHORITY_PREFIX = 'artradevault_auth_221026';


let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}
	
/**
 * 
 * Find the PDA for the metadata
 * 
 */
async function find_pda(mint_pubkey) {
	
	const [pda_metadata, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint_pubkey.toBuffer(),
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	return pda_metadata;
}

/**
 * 
 * Find the PDA for the edition
 * 
 */
async function find_pda_edition(mint_pubkey) {
	
	const [pda_edition, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('metadata'),
			new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s').toBuffer(), 
			mint_pubkey.toBuffer(),
			Buffer.from('edition'),
		],
		new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
	);
	
	return pda_edition;
}

/**
 * 
 * Find the PDA for the collection owner
 * 
 */
export async function find_pda_collection_owner() {
	
	const [pda_collection_owner, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('collection owner'),
		],
		program_id
	);
	
	return pda_collection_owner;
}

/**
 * 
 * Find the PDA for the collection owner (authority)
 * 
 */
async function find_pda_collection_authority() {
	
	const [pda_collection_authority, bum] = await PublicKey.findProgramAddress(
		[
			Buffer.from('collection owner authority'),
		],
		program_id
	);
	
	return pda_collection_authority;
}


/**
 * 
 * Create the instruction to create the metadata
 * 
 */
async function prepare_metadata_instruction(wallet_address, mint, item_metadata, collection_address) {
	
	var pda_metadata = await find_pda(mint.publicKey);
	
	if(collection_address)
		collection_address = new PublicKey(collection_address);
	
	var accounts = {
		metadata: pda_metadata, // OK
		mint: mint.publicKey, // OK
		mintAuthority: wallet_address,
		payer: wallet_address,
		updateAuthority: wallet_address,
	};
	
	var metadata = item_metadata.metadata;
	metadata.creators = item_metadata.creators;
	
	if(!collection_address) {
		
		metadata.CollectionDetails = {V1: {size: 1000}};
		metadata.collection = null;
	}
	else {
		
		metadata.collection = {
			
			key: collection_address,
			verified: false
		};
	}
	
	metadata.uses = null;
	
	var args = {
		createMetadataAccountArgsV2: {
			data: metadata,
			isMutable: true,
		},
	};
	
	var metadata_instruction = await mpl_token_metadata.createCreateMetadataAccountV2Instruction(accounts,args);
	
	return metadata_instruction;
}

async function prepare_create_account_instruction(wallet_address, mint) {
	
	return await SystemProgram.createAccount({
		fromPubkey: wallet_address,
		newAccountPubkey: mint.publicKey,
		lamports: await Token.getMinBalanceRentForExemptMint(connection),
		space: MintLayout.span,
		programId: TOKEN_PROGRAM_ID,
	});
}

async function prepare_init_mint_instruction(wallet_address, mint) {
	
	// var pda = await find_pda_collection_owner(mint.publicKey);
	
	return await Token.createInitMintInstruction(
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		0,
		wallet_address, // mint auth
		wallet_address // freeze auth
		// pda,
		// pda,
	);
}

async function prepare_create_master_edition_v3_instruction(wallet_address, mint) {
	
	// var pda = await find_pda_collection_owner(mint.publicKey);
	
	var accounts = {
		edition: await find_pda_edition(mint.publicKey),
		mint: mint.publicKey,
		updateAuthority: wallet_address,
		mintAuthority: wallet_address,
		payer: wallet_address,
		metadata: await find_pda(mint.publicKey)
	};
	
	var args = {
		createMasterEditionArgs: {
			maxSupply: 0,
		},
	};
	
	return await mpl_token_metadata.createCreateMasterEditionV3Instruction(accounts, args);
}

async function prepare_create_ata_instruction(wallet_address, mint) {
	
	// create associated token account
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		wallet_address,  // to
		true
	);
	
	const create_ata_instruction = Token.createAssociatedTokenAccountInstruction(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		ata_mint,
		wallet_address, // to
		wallet_address // signer
	);
	
	return [create_ata_instruction, ata_mint];
}

async function prepare_create_mintto_instruction(wallet_address, mint) {
	
	// create associated token account
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		wallet_address,  // to
		true
	);
	
	const mint_to_instruction = Token.createMintToInstruction(TOKEN_PROGRAM_ID, mint.publicKey, ata_mint, wallet_address, [], 1);
	
	return mint_to_instruction;
}

async function prepare_sol_transfer_instruction(wallet_address) {
	
	var instruction = await SystemProgram.transfer({
		fromPubkey: wallet_address,
		toPubkey: new PublicKey('DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac'),
		lamports: 100000000,
	})
	
	return instruction;
}

async function prepare_verify_collection_instruction(wallet_address, mint, collection_address) {
	
	collection_address = new PublicKey(collection_address);

	var accounts = {
		
		metadata: await find_pda(mint.publicKey),
		collectionAuthority: wallet_address,
		payer: wallet_address,
		updateAuthority: wallet_address,
		collectionMint: collection_address,
		collection: await find_pda(collection_address),
		collectionMasterEditionAccount: await find_pda_edition(collection_address),
		
	};
	
	// var instruction = await mpl_token_metadata.createSetAndVerifyCollectionInstruction(accounts);
	var instruction = await mpl_token_metadata.createSetAndVerifySizedCollectionItemInstruction(accounts);
	
	
	
	return instruction;
}

async function prepare_fixed_price_instruction(wallet_provider, mint, token_account) {
	
	const options = anchor.Provider.defaultOptions();
	const provider = new anchor.Provider(connection, wallet_provider, options);

	anchor.setProvider(provider);
	var program = new anchor.Program(idl, program_id, provider);
	
	// 5 USD
	var price = 500;
	
	var number_random_for_seeds = Math.floor(Math.random() * 256)
	
	const [_vault_account_pda, _vault_account_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_ACCOUNT_PREFIX),[number_random_for_seeds], mint.publicKey.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);
	
	var vault_account_pda = _vault_account_pda;
	
	const [vault_account_authority, _vault_account_authority_bump] = await PublicKey.findProgramAddress(
		[Buffer.from(VAULT_AUTHORITY_PREFIX),[number_random_for_seeds], mint.publicKey.toBuffer(), wallet_provider.publicKey.toBuffer()],
		program_id
	);
	
	const escrow = anchor.web3.Keypair.generate();
	
	console.log("escrow pub key", escrow.publicKey.toString());
	
	const instruction = await program.instruction.list(

		// arguments
		new anchor.BN(price), 
		number_random_for_seeds, 
		
		{
			// accounts
			accounts: {
				mint: mint.publicKey,
				seller: wallet_provider.publicKey,
				pdaMetadata: await find_pda(mint.publicKey),
				vaultAuthority: vault_account_authority,
				vaultAccount: vault_account_pda,
				tokenAccount: token_account,
				escrowAccount: escrow.publicKey,
				systemProgram: anchor.web3.SystemProgram.programId,
				rent: anchor.web3.SYSVAR_RENT_PUBKEY,
				tokenProgram: TOKEN_PROGRAM_ID,
			},
			
			// other instructions
			// instructions: [
				
			// ],
		}
	);
	
	var instruction_escrow = await program.account.escrowAccount.createInstruction(escrow);
	
	return [instruction, instruction_escrow, escrow];
}

async function prepare_program_instruction(wallet_provider, wallet_address, mint, collection_address) {
	
	var program = anchor_init(connection, wallet_provider, program_id, idl);
	
	console.log('wallet_provider', wallet_provider);
	console.log('wallet_address', wallet_address.toString());
	console.log('mint', mint.publicKey.toString());
	console.log('collection_address', collection_address);
	
	collection_address = new PublicKey(collection_address);
	
	const ata_mint = await Token.getAssociatedTokenAddress(
		ASSOCIATED_TOKEN_PROGRAM_ID,
		TOKEN_PROGRAM_ID,
		mint.publicKey,
		wallet_address,  // to
		true
	);
	
	var accounts = {
		
		payer: wallet_address,
		mplProgram: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
		mint: mint.publicKey,
		payerAta: ata_mint,
		metadata: await find_pda(mint.publicKey),
		collectionMint: collection_address,
		collectionMetadata: await find_pda(collection_address),
		collectionMasteredition: await find_pda_edition(collection_address),
		
		systemProgram: anchor.web3.SystemProgram.programId,
		rent: anchor.web3.SYSVAR_RENT_PUBKEY,
		tokenProgram: TOKEN_PROGRAM_ID,
		associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
	};
	
	var instruction = await program.instruction.mintNft({accounts: accounts});
	
	return instruction;
}

/**
 * 
 * Test artrade
 * 
 */
export async function mint_artrade(wallet_provider, wallet_address) {
	
	if(!connection)
		connection = await establishConnection();
	
	var transactions = [];
	
	var count = 1;
	
	for(var i = 0; i<count; i++) {
		
		
		wallet_address = new PublicKey(wallet_address);
		
		var transaction = new Transaction();
		
		// 1st step : mint the collection
		
		var mint = await Keypair.generate();
		
		var collection_data = {
			creators: [
				{share: 80, verified: false, address: new PublicKey("8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA")},
				{share: 20, verified: false, address: new PublicKey("AuToz9gEG4f4fh67zo2EiwFKvW6tneMYE6UNDmX95nrL")}
			],
			metadata: {
				name: 'TEST COLLECTION',
				symbol: 'TEST',
				uri: 'https://cdn.effe2.xyz/storage/nft/bonks/collection.json',
				sellerFeeBasisPoints: 1000,
			}
		};
		var metadata_instruction = await prepare_metadata_instruction(wallet_address, mint, collection_data, null);
		var create_account_instruction = await prepare_create_account_instruction(wallet_address, mint);
		var init_mint_instruction = await prepare_init_mint_instruction(wallet_address, mint);
		var [create_ata_instruction, osef] = await prepare_create_ata_instruction(wallet_address, mint);
		var mint_to_instruction = await prepare_create_mintto_instruction(wallet_address, mint);
		var create_master_edition_v3_instruction = await prepare_create_master_edition_v3_instruction(wallet_address, mint);
		
		transaction.add(create_account_instruction);
		transaction.add(init_mint_instruction);
		transaction.add(metadata_instruction);
		transaction.add(create_ata_instruction);
		transaction.add(mint_to_instruction);
		transaction.add(create_master_edition_v3_instruction);
		
		
		console.log("OK");
		/*
		// 2nd step : mint the nft
		var mint_nft = await Keypair.generate();
		var nft_data = {
			creators: [
				// {share: 80, verified: false, address: new PublicKey("8SZTojxgUPmKDkiBm4rg5K7tm6c1STLvn7L2a68jn9AA")},
				{share: 50, verified: false, address: new PublicKey("Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH")},
				{share: 35, verified: false, address: new PublicKey("DRA8e6NGeHU7NPpjbEwDgdPVQYDEQT4Da1coYeoeg2ac")},
				{share: 10, verified: false, address: new PublicKey("HBpK1FiEEHTaLCU75Xb56VFGZepcsG3Q7SJusBTryzS7")},
				{share: 5, verified: false, address: new PublicKey("3jPiBjJ43pvjr6S8BwSoBot38NBsL5KzqboV5tSugqxS")},
			],
			metadata: {
				name: 'TEST COLLECTION #1',
				symbol: '',
				uri: 'https://cdn.effe2.xyz/storage/nft/bonks/json/000a0bce3f2ae9eea00eead5dcdea752.json',
				sellerFeeBasisPoints: 1000,
			}
		};
		
		// var collection_address = "2t5oCaZ12f1t8Q1TYYQe39RPv2kwntMoEqiCEVKDDb5n"; // devnet artrade
		var collection_address = "VyFfoVpVBwsCnNL9kxnSavA44WhBFGMpD88NWxAauJV"; // mainnet fred
		
		var create_account_instruction_nft = await prepare_create_account_instruction(wallet_address, mint_nft);
		var init_mint_instruction_nft = await prepare_init_mint_instruction(wallet_address, mint_nft);
		var metadata_instruction_nft = await prepare_metadata_instruction(wallet_address, mint_nft, nft_data, collection_address);
		var [create_ata_instruction_nft, ata_mint] = await prepare_create_ata_instruction(wallet_address, mint_nft);
		var mint_to_instruction_nft = await prepare_create_mintto_instruction(wallet_address, mint_nft);
		var create_master_edition_v3_instruction_nft = await prepare_create_master_edition_v3_instruction(wallet_address, mint_nft);
		var create_program_instruction_nft = await prepare_program_instruction(wallet_provider, wallet_address, mint_nft, collection_address);
		
		// verify collection
		var verify_collection_instruction_nft = await prepare_verify_collection_instruction(wallet_address, mint_nft, collection_address);
		
		
		// Sol transfer
		var sol_transfer_instruction = await prepare_sol_transfer_instruction(wallet_address);
		
		// Fixed price instruction
		var [fixed_price_instruction, instruction_escrow, escrow] = await prepare_fixed_price_instruction(wallet_provider, mint_nft, ata_mint);
		
		// transaction.add(create_account_instruction_nft);
		// transaction.add(init_mint_instruction_nft);
		// transaction.add(create_ata_instruction_nft);
		// transaction.add(create_master_edition_v3_instruction_nft);
		transaction.add(create_program_instruction_nft);
		transaction.add(metadata_instruction_nft);
		// transaction.add(mint_to_instruction_nft);
		// transaction.add(verify_collection_instruction_nft);
		
		// transaction.add(sol_transfer_instruction);
		transaction.add(instruction_escrow);
		transaction.add(fixed_price_instruction);
		
		
		
		// console.log('mint', mint.publicKey.toString());
		// console.log('mint_nft', mint_nft.publicKey.toString());
		// console.log('escrow', escrow.publicKey.toString());
		
		console.log('mint_nft', mint_nft.publicKey.toString());
		console.log('ata_mint', ata_mint.toString());
		console.log('collection_address', collection_address.toString());
		*/
		
		
		
		
		
		transaction.feePayer = new PublicKey(wallet_provider.publicKey);
		transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
		// transaction.sign(mint_nft, mint);
		// transaction.sign(mint_nft, escrow);
		transaction.sign(mint);
		// transaction.sign(mint_nft);
		
		// console.log('transaction size', transaction.serialize());
		
		var signature = await signAndSendTransaction(wallet_provider, connection, transaction);
		
		console.log('signature', signature);
	
		return [signature];
		
		// transactions.push(transaction);
	}
	
	// var signatures = await signAndSendMultipleTransactions(wallet_provider, connection, transactions);
	
	// return signatures;	
}
