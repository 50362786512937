<template>
	<div>
		
		
		<div id="header">
			
			<div class="header-mobile mobile">
				<img :src="project.logo" />
				<div id="connect_wallet">
					<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
					<div v-else>
						{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
						Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
					</div>
				</div>
			</div>
			<div style="width: 320px; text-align: left; padding: 17px;"><img :src="project.logo" class="desktop" /></div>
			<div id="connect_wallet"  class="desktop">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}<br/>
					Balance: {{ token_balance.toFixed(2) }} {{ project.token_name }}
				</div>
			</div>
			<div class="advertisement">
				<a href="https://effe2.xyz/" target="_blank" style="color: #fff; text-decoration: none;"> 
					Powered by Effe²<br/>
					<span>Free tools for NFT projects on Solana</span>
				</a>
			</div>
		</div>
		
		<div id="raffles">
			<div class="raffle-container">
				<div class="raffle-button button-primary" @click="mint()" style="padding: 10px 50px;margin-top: 0px;" v-if="$root.wallet_address !== null">Mint</div>
							
			</div>
		</div>
		
	</div>
</template>


<script>


import { mint_artrade } from '@/libs/rustProgramArtrade';
import { mint, check_escrow, fetch_all_escrow_wl_limited, fetch_all_escrow_wl_unlimited } from '@/libs/rustProgram';
import {TweenMax, Quart} from 'gsap';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {getProject} from '@/libs/project';
import $ from 'jquery';
import { establishConnection, getTokenAccountForTokenAndOwner, getTokenAccountBalance, confirmTransaction, getNftOwned, getNftMetadata } from "@/libs/solanaConnection";
import { PublicKey } from '@solana/web3.js';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

var connection = null;


export default {
	name: 'Staking',
	components: {},

	data: function () {

		return {
			
			token_balance: 0,
			project: {},
			collection_address: "EdBqUHxPHa6D1UU79nRVAWD4eyf79cYRqoExVF8b5S7R",
			escrow_address: "GKfjV2yG8kSbRNouz1oK7jDNGvC2X7Vf1uSet7MuSF7Y",
			supply: 3333,
			minted: 0,
			phases: [
				{name: 'Holders free mint', type:'wl_limited', spots: 0, max: 0, count: 0, time_left: false, start: 1673722800, end: 1673913599},
				// {name: 'Holders free mint', type:'wl_limited', spots: 0, max: 0, count: 0, time_left: false, start: 1673705863, end: 1673726400},
				{name: 'WL mint (9.2M $bonk)', type:'wl_unlimited', spots: 0, max: 10, count: 0, time_left: false, start: 1673726400, end: 1673913599, wallets: []},
				// {name: 'WL mint (8.5M $bonk)', type:'wl_unlimited', spots: 0, max: 10, count: 0, time_left: false, start: 1673705863, end: 1673827199, wallets: []},
				{name: 'Public mint (10.2M $bonk)', type:'public', spots: 0, max: 10, count: 0, time_left: false, start: 1673730000, end: 1673913599},
			]
		}
	},
	
	computed: {
		
		percentage_minted_px: function() {
			
			return Math.round(this.minted / this.supply * 500);
		},
		
		percentage_minted_mobile_px: function() {
			
			return Math.round(this.minted / this.supply * 200);
		},
		
		percentage_minted: function() {
			
			return Math.round(this.minted / this.supply * 10000) / 100;
		},
		
	},

	created: function(){
		
		var $this = this;
		
		$this.check_escrow(true);
		
		this.$root.$on('projectSelected', function() {
		
			$this.project = $this.$root.project;
		
		});
		
		this.$root.$on('walletConnected', function() {
			
			$this.get_token_balance();
			
			$this.get_phase_state();
			
		});
		
		this.$root.get_project_ready = true;
		
		this.$root.$emit('openPopup');
		
		for(var phase of this.phases) {
			
			this.compute_time_left(phase);
			this.get_wallets_for_phase(phase);
			
		}
	},
	mounted: function(){
	
	},

	methods: {
	
		check_escrow: async function(settimeout) {
		
			var escrow_data = await check_escrow(null, null, this.escrow_address);
			
			this.supply = escrow_data.supply;
			this.minted = escrow_data.currentId;
			
			if(settimeout === true) {
			
				var $this = this;
				setTimeout(function() { $this.check_escrow(true); }, 10000);
			}
		},
	
		button_add: function(phase) {
			
			if(phase.spots == 0)
				return false;
				
			if(phase.count >= phase.max)
				return false;
				
			if(phase.time_left === false)
				return false;
				
			if(phase.time_left.type == 'before')
				return false;
			
			return true;
		},
	
		button_remove: function(phase) {
			
			if(phase.spots == 0)
				return false;
				
			if(phase.count <= 0)
				return false;
				
			if(phase.time_left === false)
				return false;
				
			if(phase.time_left.type == 'before')
				return false;
			
			return true;
		},
	
		
	
		get_phase_state: function() {
		
			var wallet_address = getWalletAddress();
			var wallet;
			
			for(var phase of this.phases) {
				
				if(phase.type == 'public') {
					
					phase.spots = -1;
				}
				
				if(phase.type == 'wl_limited') {
				
					for(wallet of phase.wallets) {
						
						if(wallet.wallet == wallet_address && wallet.escrow_address != 'BPfdvm4kz3HqwuZKKV81NdYrUb4sqsdKb6WciJnG8vxE') {
						
							
							if(wallet.spots <= 10)
								phase.max = wallet.spots;
							else 
								phase.max = 10
							
							phase.count = 0;
							phase.spots = wallet.spots;
							phase.escrow_wl_address = wallet.escrow_address;
						}
					}
				}
				
				if(phase.type == 'wl_unlimited') {
					
					for(wallet of phase.wallets) {
						
						if(wallet.wallet == wallet_address) {
							
							phase.max = 10;
							phase.count = 0;
							phase.spots = -1;
							phase.escrow_wl_address = wallet.escrow_address;
						}
					}
					
					
				}
			}
		},
		
		get_wallets_for_phase: async function(phase) {
			
			if(phase.type == 'public') {
				
				return;
			}
			
			var wallets;
			
			if(phase.type == 'wl_unlimited') {
				
				wallets = await fetch_all_escrow_wl_unlimited();
				
				phase.wallets = wallets;
				return;
			}
			
			if(phase.type == 'wl_limited') {
			
				wallets = await fetch_all_escrow_wl_limited();
			
				// console.log(wallets);
				
				phase.wallets = wallets;
				return;
			}
			
			phase.wallets = wallets;
		},
	
		compute_difference_between_2_dates: function(date_end, date_start) {
			
			var difference = date_end - date_start;
			
			var hours = Math.floor(difference / 3600);
			var minutes = Math.floor((difference - hours * 3600) / 60);
			var seconds = difference - hours * 3600 - minutes * 60;
			
			if(hours < 10)
				hours = "0" + hours;
				
			if(minutes < 10)
				minutes = "0" + minutes;
				
			if(seconds < 10)
				seconds = "0" + seconds;
			
			return {
				
				hours: hours,
				minutes: minutes,
				seconds: seconds,
			};
		},
	
		compute_time_left: function(phase) {
			
			var timestamp_finish = phase.end;
			var timestamp_start = phase.start;
			
			var current_date = new Date();
			var timestamp_now = Math.round(current_date.getTime() / 1000);
			
			// phase over
			if(timestamp_now >= timestamp_finish) {
				
				phase.time_left = false;
				return;
			}
			
			var $this = this;
			
			// phase not started
			if(timestamp_now < timestamp_start) {
			
				phase.time_left = this.compute_difference_between_2_dates(timestamp_start, timestamp_now);
				phase.time_left.type = 'before';
				
				setTimeout(function() {
					
					$this.compute_time_left(phase);
				}, 1000);
				
				return;
			}
			
			phase.time_left = this.compute_difference_between_2_dates(timestamp_finish, timestamp_now);
			phase.time_left.type = 'run';
			
			setTimeout(function() {
				
				$this.compute_time_left(phase);
			}, 1000);
		},
	
		remove_1_mint: function(phase) {
		
			if(!this.button_remove(phase))
				return;
		
			phase.count--;
		},
	
		add_1_mint: function(phase) {
			
			if(!this.button_add(phase))
				return;
				
			phase.count++;
		},
		
		mint: async function() {
			
			var signatures, confirmed_transaction, all_confirmed;
			
			var project = this.project;
			var nfts = this.staked;
			
			this.$root.$emit('openLoader');
			
			try {
				
				signatures = await mint_artrade(getSolanaObject(), getWalletAddress());
				
				all_confirmed = true;
				
				for(var signature of signatures) {
				
					confirmed_transaction = await confirmTransaction(signature);
					
					if(confirmed_transaction === false)
						all_confirmed = false;
				}
				
				if(signatures.length == 0) {
					
					this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
					this.$root.$emit('openModal');
				}
				else {
				
					if(all_confirmed === true) {
					
						this.$root.modal = {title: 'Perfect !', text: "All NFTs minted successfully !"};
						this.$root.$emit('openModal');
					}
					else {
						
						this.$root.modal = {title: 'Oops !', text: "At least one transaction did not confirm in time, please refresh the page and try again if necessary"};
						this.$root.$emit('openModal');
					}
				}
				
				this.check_escrow(false);
				
				for(var phase_tmp of this.phases) {
					
					await this.get_wallets_for_phase(phase_tmp);	
				}
				
				this.get_phase_state();
				
				
			}
			catch(e) {
				
				console.log(e);
				this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
				this.$root.$emit('openModal');
			}
			
			this.$root.$emit('closeLoader');
			
		},
	
		get_token_balance: async function() {
			
			if(!connection)
				connection = await establishConnection();
			
			var buyer_token_account = await getTokenAccountForTokenAndOwner(this.project.token_address, getWalletAddress());
			
			var balance = await getTokenAccountBalance(new PublicKey(buyer_token_account));
			
			this.token_balance = balance;
		},
		
		
	
		
		
	},
	watch:{}
	}
</script>



